<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        @back="hRedirect('apps-experts-videosnippets-list', { id: $route.params.expertid})"
        @save="createItem"
        :title="cardTitle"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    >
    </b-card-actions>
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form
            @submit.stop.prevent="handleSubmit(createItem)"
            @reset.prevent="resetForm"
        >
          <b-card>
          <div class="mb-2">
            <b-form-checkbox
                v-model="currentItem.status"
                value="1"
                name="status"
                class="custom-control-success"
            >
              {{ $t("Active") }}
            </b-form-checkbox>
          </div>
          <div>
            <hr />
            <b-form-group :label="$t('Name')" label-for="Name">
              <validation-provider
                  #default="validationContext"
                  :name="$t('Name')"
                  rules="required"
              >
              <b-form-input
                  v-model="currentItem.name"
                  :name="$t('Name')"
                  id="name"
                  :placeholder="$t('Name')"
              />
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
              <small v-if="validate && Object.keys(validation).includes('name')" class="text-danger">{{ $t(validation.name[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </div>

          <b-row>
            <b-col md="12" xl="23" class="mb-1">
              <b-form-group :label="$t('Start Date')" label-for="startdate">
                <validation-provider
                    #default="validationContext"
                    :name="$t('startdate')"
                    rules="required"
                >
                <b-form-datepicker
                    id="startdate"
                    :name="$t('startdate')"
                    :placeholder="$t('Choose your start Date')"
                    v-model="currentItem.startdate"
                />
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
                <small v-if="validate && Object.keys(validation).includes('startdate')" class="text-danger">{{ $t(validation.startdate[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group
              class="mt-2"
              :label="$t('Content Html')"
              label-for="Content Html"
          >
            <quill-editor
                v-model="currentItem.description"
                :options="editorOption"

            >
              <div id="toolbar" slot="toolbar">
                <!-- Add a bold button -->
                <button class="ql-bold">Bold</button>
                <button class="ql-italic">Italic</button>

                <!-- Add font size dropdown -->

                <select class="ql-size">
                  <option value="small" />
                  <option selected />
                  <option value="large" />
                  <option value="huge" />
                </select>

                <!-- Add subscript and superscript buttons -->
                <button class="ql-script" value="sub" />
                <button class="ql-script" value="super" />
              </div>
            </quill-editor>
          </b-form-group>
          </b-card>
          <b-card>
                <b-button variant="success" type="submit">
                  {{ $t("Save") }}
                </b-button>
          </b-card>
        </b-form>
      </validation-observer>
  </component>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BFormTextarea,
  BCardText,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormFile,
  BAvatar,
  BMedia,

} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, onUnmounted, mounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";

import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import { required, alphaNum, email } from "@validations";
import BCardActions from '@core/components/b-card-actions/BCardActions'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    vSelect,
    BFormFile,
    BAvatar,
    BMedia,
    quillEditor,
    BCardActions,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      loaded: true,
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },

      currentItem: {
        name: "",
        startdate: "",
        status: 1,
        description: "",
      },
      validation: {},
      validate: false,
    };
  },

  methods: {
    createItem() {
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {};
            this.validate = false;

      if (success) {
        this.helperAddItem(`/experts/${this.$route.params.expertid}/videosnippets`, this.currentItem, this.$refs.refFormObserver, null, (response) => {
          this.$router.push(
              {
                name: 'apps-experts-videosnippets-edit',
                params: { expertid: this.$route.params.expertid, videonuggetid: response.data.id },
              },
              () => setTimeout(() => {
                this.hToast('success', 'Success', 'Item saved')
              })
          )
        })
      } else {
        this.loaded = false
        setTimeout(() => {
          this.loaded = true
        })
      }
     })
    },

    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
  },
  beforeMount() {
    this.addBreadcrumbDataFromRouteParams(this.$route.params)
  },
  mounted() {
    this.$http.get(`/experts/${this.$route.params.expertid}`)
        .then(response => {
          this.cardTitle = this.currentItem.name + " (" + response.data.firstname + " " +response.data.name + ")"
        })
  }
};
</script>

<style>
.quill-editor .ql-container {
  height: 250px;
}
</style>
